header {
    box-shadow: 0 5px 10px rgba(0, 0, 0, .5);
    z-index: 10;
    background-color: #fff;
    width: 100%;
    position: fixed;

    .nav-top {
        background-color: #000;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        padding: 5px 20px;
        color: #fff;
        column-gap: 10px;

        @media only screen and (max-width: $ms) {
            text-align: center;
            padding: 5px;
        }

        p {
            font-size: .75rem;
        }

        a {
            font-size: .75rem;

            span i {
                font-size: .95rem;

            }
        }
    }

    .nav-bottom {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-inline: 20px;

        h1 {
            font-size: .75rem;

            img {
                height: 75px;
                width: 75px;
                object-fit: cover;
            }
        }

        .nav-bottom__link {
            display: flex;
            align-items: center;
            column-gap: 40px;

            .navigation {

                ul {
                    display: flex;
                    column-gap: 40px;
                    list-style: none;
                    z-index: 10;

                    li {
                        transition: border-bottom .75s ease-in-out;
                        padding-bottom: 1px;

                        &:hover {
                            padding-bottom: 0;
                            border-bottom: 1px solid #fff;
                        }

                        a {
                            font-family: sans-serif;
                            text-decoration: none;
                            color: #000;

                            &.nav-active {
                                font-weight: 600;
                            }

                            &.active {
                                font-weight: 900;
                                text-decoration: underline;
                            }

                            @media only screen and (max-width: 768px) {
                                font-size: .75rem;
                            }
                        }
                    }

                }

                @media only screen and (max-width: 515px) {
                    position: fixed;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    background-color: rgba(0, 0, 0, .5);
                    z-index: 100;
                    display: none;


                    &.active__menu {
                        transform: translateX(0);
                    }

                    ul {
                        transition: width .3s ease-in;
                        position: absolute;
                        top: 0;
                        right: 0;
                        width: 250px;
                        height: 100%;
                        background-color: #fff;
                        z-index: 100;
                        flex-direction: column;
                        text-align: center;
                        justify-content: center;

                        li {
                            width: 100%;

                            a {
                                width: 100%;
                                padding-block: 10px;
                                display: flex;
                                justify-content: center;

                                &.active {
                                    font-weight: 900;
                                    text-decoration: underline;
                                }
                            }
                        }
                    }

                    &.active__menu {
                        display: flex;
                    }
                }
            }

            .mobile__menu {
                display: none;

                @media only screen and (max-width: 515px) {
                    display: flex;
                }
            }

        }
    }
}

footer {
    background: url("assets/footer-background-img.webp");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-attachment: fixed;
    color: #fff;
    padding: 75px 50px;
    display: flex;
    flex-direction: column;
    row-gap: 20px;

    @media only screen and (max-width: 515px) {
        padding: 50px 20px;
    }

    .footer__info {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        gap: 50px;

        .footer__block {
            display: flex;
            flex-direction: column;
            row-gap: 20px;

            @media only screen and (max-width: 515px) {
                row-gap: 10px;
            }
        }

        .footer__shop h2 {
            margin-bottom: 20px;
        }

        .footer__block,
        .footer__shop {
            h2 {
                font-size: 1.25rem;
                font-weight: 600;
            }

            .footer-block__link {
                display: flex;
                align-items: center;
                flex-wrap: wrap;
                gap: 10px;

                &.shop {
                    flex-direction: column;
                    align-items: normal;
                }

                .footer-block__shop {
                    display: flex;
                    flex-direction: column;
                    gap: 10px;

                    h3 {
                        font-size: .85rem;
                        font-weight: 600;
                    }

                    iframe {
                        border: none;
                    }

                    .shop__location,
                    .shop__opening {
                        display: flex;
                        align-items: center;
                        column-gap: 10px;

                        svg {
                            width: 25px;
                            height: 25px;
                        }
                    }
                }

                a {
                    font-size: .75rem;
                }

                hr {
                    height: 100%;
                }
            }

            .img__insta {
                display: flex;
                gap: 20px;

                a {
                    width: 125px;
                    height: 125px;
                    position: relative;
                    overflow: hidden;

                    &::before {
                        content: "";
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        font-size: 2.5rem;
                        position: absolute;
                        left: 0;
                        bottom: -100%;
                        width: 100%;
                        height: 100%;
                        backdrop-filter: blur(5px);
                        background-color: rgba(255, 218, 185, .5);
                        transition: bottom .5s;
                    }

                    .icon__magnifer {
                        position: absolute;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        transition: bottom .5s;
                        bottom: -100%;

                        svg {
                            width: 35px;
                            height: 35px;
                            color: #fff;
                        }
                    }

                    &:hover::before,
                    &:hover .icon__magnifer {
                        bottom: 0;
                    }

                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;

                        &::before {
                            z-index: 10;
                            background-color: red;
                        }
                    }


                }


                @media only screen and (max-width: 515px) {
                    gap: 10px;

                    a {
                        width: 50px;
                        height: 50px;
                    }
                }

            }
        }
    }

    .footer__bottom {
        display: flex;
        align-items: center;
        justify-content: space-between;

        p {
            font-size: .75rem;
        }

        .footer-bottom__icon {
            display: flex;
            align-items: center;
            gap: 10px;

            a {
                font-size: 1rem;

                span i {
                    font-size: 1.25rem;
                }
            }

        }
    }
}