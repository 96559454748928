.infos {
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    margin-bottom: 75px;

    @media only screen and (max-width: 425px) {
        display: flex;
        flex-direction: column;
    }

    @media only screen and (max-width: 320px) {
        display: flex;
        flex-direction: column;
        margin-bottom: 20px;
    }

    .info__card {
        flex: 0 1 calc(30.4% - 50px);
        margin-bottom: 20px;
        display: flex;
        flex-direction: column;
        align-items: center;
        box-shadow: -10px 5px 0 $style-gradient;
        border: 1px solid $style-gradient;
        margin: 30px;

        @media only screen and (max-width: 768px) {
            flex: 0 1 calc(50% - 20px);
        }

        .info__card-img {
            width: 50%;
            height: 30%;
            margin: 30px;

            svg {
                width: 100%;
                height: 100%;
                color: $text;

                @media only screen and (max-width: 425px) {
                    width: 40%;
                }
            }
        }

        h4 {
            font-size: 0.8rem;
            margin-bottom: 5px;
        }

        p {
            color: $text;
            display: flex;
            text-align: center;
            justify-content: center;
            font-size: 11px;
            width: 50%;

            @media only screen and (max-width: 425px) {
                font-size: 14px;
            }
        }
    }
}