section.banner {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-attachment: fixed;
    height: 750px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding-inline: 20px;
    gap: 20px;

    span {
        border: 1px solid;
        padding: 5px;
        backdrop-filter: blur(15px);

        p {
            font-size: 11px;
        }
    }

    h2 {
        font-size: 4rem;
        font-weight: 100;
        backdrop-filter: blur(5px);
    }

    .banner__btn {
        display: flex;
        align-items: center;
        padding: 20px;
        border: none;
        background-color: #fff;
        column-gap: 5px;
    }

    @media only screen and (max-width: 768px) {
        span {
            color: #fff;
            background-color: rgb(255, 255, 255, .25);
        }
        h2 {
            color: #fff;
        }
    }
}