.discount {
    background: url("assets/publicity_background.webp");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-attachment: fixed;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    gap: 50px;
    margin-block: 100px;
    padding: 20px;

    svg {
        top: 0;
        left: 0;
        width: 100%;
        position: absolute;
    }

    .vertical-line {
        border: 1px solid black;
        height: 100px;
    }

    h3 {
        font-size: 2rem;
        font-weight: 100;
        width: 50%;
        backdrop-filter: blur(5px);

        @media only screen and (max-width: 1023px) {
            width: 100%;
        }

        @media only screen and (max-width: 425px) {
            font-size: 1.5rem;
        }
    }

    button {
        background-color: #000;
        border: none;
        color: #fff;
        padding: 20px;
        font-family: sans-serif;
        font-size: .85rem;
        cursor: pointer;
        transition: background-color .3s ease-in, color .3s ease-in;

        &:hover {
            background-color: rgb(255, 255, 255, 05);
            color: #000;

        }
    }

    .time {
        display: flex;
        gap: 20px;

        .time__block {
            h2 {
                font-size: 3rem;
                font-family: sans-serif;
            }

            h3 {
                color: $text;
                width: 100%;
                text-align: center;
                font-weight: 700;
                font-size: .85rem;
            }
        }
    }
}