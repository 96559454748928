@import url('https://fonts.googleapis.com/css2?family=Lora:ital,wght@0,400..700;1,400..700&display=swap');

/* Couleur */

$text: #717171;
$style: #FFDAB9;
$style-gradient: rgba(255, 218, 185, .5);

/* Taille d'écran */

$ss: 425px;
$ms: 768px;
$ls: 1024px;

/* Style global */

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: Lora, sans-serif;
}

#root {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100vh;
}

a {
    color: unset;
    text-decoration: none;
    font-family: sans-serif;
}

ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

p {
    font-size: .75rem;
    font-family: sans-serif;
}

@mixin carouselLogo {
    .work-with {
        margin-block: 100px;
        text-align: center;

        h3 {
            font-size: 2rem;
            font-weight: 100;
            margin-top: 50px;
        }
    }
}

@mixin carouselNews {
    .news {
        display: flex;
        flex-direction: column;
        align-items: center;
        row-gap: 20px;
        margin-block: 100px;
        position: relative;

        &::before {
            content: "";
            position: absolute;
            top: 35%;
            left: 0;
            z-index: -1;
            background-color: $style-gradient;
            // clip-path: polygon(0% 0%, 100% 20%, 100% 100%, 0% 80%);
            width: 100%;
            height: 200px;
        }

        h3 {
            font-size: 2rem;
            font-weight: 100;
            margin-top: 50px;
            text-align: center;
            padding-inline: 20px;

            @media only screen and (max-width: 768px) {
                font-size: 1.5rem;
            }
        }
    }
}

@mixin info {
    .contact-info__style {
        // background-color: $style-gradient;
        padding-inline: 10%;
        margin-bottom: 100px;
    }
    .contact-infos {
        position: relative;
        background-color: #fff;
        display: flex;
        flex-direction: column;
        gap: 50px;

        .contact-infos__title {
            font-size: 2rem;
            font-weight: 100;
            display: flex;
            justify-content: center;

            @media only screen and (max-width: 425px) {
                font-size: 1.5rem;
            }
        }
    }
}