.come-in {
  h1 {
    padding: 20px;
    display: flex;
    justify-content: center;
    margin-top: 80px;
  }

  .cards {
    position: relative;

    &::before {
      content: "";
      position: absolute;
      left: 5%;
      top: 15%;
      width: 90%;
      height: 750px;
      background-color: $style-gradient;
      z-index: -1;

      @media only screen and (max-width: 1024px) {
        height: 475px;
      }

      @media only screen and (max-width: 779px) {
        top: 15%;
        height: 450px;
      }
      @media only screen and (max-width: 488px) {
        left: 0;
        width: 100%;
        top: 10%;
      }
    }

    .cards-shop {
      margin-bottom: 100px;
      padding-inline: 20px;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      gap: 30px;

      @media only screen and (max-width: 425px) {
        margin-bottom: 50px;
      }

      .shop__video {
        margin-block: 100px;

        @media only screen and (max-width: 1024px) {
          video {
            height: 500px;
          }
        }

        @media only screen and (max-width: 488px) {
          margin-block: 50px;
          // video {
          //   height: 320px;
          // }
        }
      }

      .text-card {
        width: 30%;
        display: flex;
        flex-direction: column;
        gap: 10px;

        @media only screen and (max-width: 779px) {
          margin-inline: 50px;
          width: 100%;
        }

        h3 {
          font-size: 1.5rem;
          font-weight: 550;
          color: black;
          margin-bottom: 10px;

          @media only screen and (max-width: 1024px) {
            font-size: 1.25rem;
          }

          @media only screen and (max-width: 779px) {
            text-align: center;
          }

          @media only screen and (max-width: 425px) {
            font-size: 0.9rem;
          }
        }

        p {
          font-size: 1rem;
          color: $text;
          text-align: justify;

          &.descr-shop {
            color: #000;
            font-weight: 600;
          }

          @media only screen and (max-width: 1024px) {
            font-size: .75rem;
          }

          @media only screen and (max-width: 425px) {
            font-size: 11px;
          }
        }
      }
    }
  }

  @include carouselLogo;

  .cards-categories {
    padding: 20px;
    text-align: center;

    @media only screen and (max-width: 425px) {
      margin-bottom: 30px;
      padding: 10px;
    }

    @media only screen and (max-width: 320px) {
      padding: 0px;
    }

    h3 {
      display: flex;
      justify-content: center;
      font-size: 2rem;
      font-weight: 100;
      margin-bottom: 80px;

      @media only screen and (max-width: 425px) {
        font-size: 1.5rem;
        margin-bottom: 35px;
      }

      @media only screen and (max-width: 320px) {
        font-size: 1.4rem;
      }
    }
  }

  @include carouselNews;
  @include info;
}