.cards {
  .cards-shop {
    margin-bottom: 50px;
    padding-inline: 20px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 30px;


    .card {
        position: relative;
        width: 380px;
        // background-color: $style1;
        box-shadow: 15px 20px 10px rgba(0, 0, 0, 0.25);
   
        img {
            width: 100%;
            height: 450px;
            object-fit: cover;
          }
          h3{
            font-size: 0.9rem;
            font-weight: 550;
            color: black;
          }
          .descr-shop{
            bottom: 0;
            padding: 20px;
          }
          .shop-more{
            font-weight: 10px;
          }
    }


  }
}
