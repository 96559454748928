.all-block {
    display: flex;
    overflow: hidden;
    padding-block: 50px;
    width: 100%;
    gap: 30px;

    .block-clothes {
        display: flex;
        justify-content: center;
        gap: 30px;

        .clothe__card {
            width: 500px;

            @media only screen and (max-width: 768px) {
                width: 250px;
            }

            img {
                display: flex;
                width: 100%;
                height: 250px;
                object-fit: contain;
            }

            .clothe__info {
                display: flex;
                // align-items: center;
                margin-block: 5px;
                gap: 20px;

                h4 {
                    font-size: 1rem;
                    display: flex;

                    span {
                        font-size: 3rem;
                        font-weight: 100;
                    }

                    @media only screen and (max-width: 768px) {
                        font-size: .75rem;

                        span {
                            font-size: 2.5rem;
                        }
                    }
                }

                .clothe__desc {
                    display: flex;
                    flex-direction: column;
                    gap: 5px;

                    h5 {
                        font-weight: 100;
                        font-size: 1.25rem;
                    }

                    p {
                        font-family: Lora;
                        color: $text;
                    }

                    @media only screen and (max-width: 768px) {
                        h5 {
                            font-size: 1rem;
                        }

                        p {
                            font-size: 11px;
                        }
                    }
                }
            }
        }
    }
}