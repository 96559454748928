.all-logo {
    display: flex;
    overflow: hidden;
    padding-block: 50px;
    width: 100%;

    .block-logo {
        display: flex;
        align-items: center;

        .logo {
            display: flex;
            align-items: center;
            width: 100px;
            height: 100px;
            padding-inline: 10px;

            img {
                width: 100%;
                object-fit: cover;
            }
        }
    }
}