.come-in-men {
  h1 {
    padding: 20px;
    display: flex;
    justify-content: center;
    margin-top: 80px;
  }

  .cards {
    position: relative;

    &::before {
      content: "";
      position: absolute;
      left: 5%;
      top: 15%;
      width: 90%;
      height: 630px;
      background-color: $style-gradient;
      z-index: -1;

      @media only screen and (max-width: 1024px) {
        height: 450px;
        top: 18%;
      }

      @media only screen and (max-width: 779px) {
        height: 400px;
        top: 15%;
      }

      @media only screen and (max-width: 537px) {
        left: 0;
        width: 100%;
        top: 13%;
      }
    }

    .cards-shop {
      margin-bottom: 100px;
      padding-inline: 20px;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
      gap: 30px;

      @media only screen and (max-width: 768px) {
        margin-bottom: 50px;
      }

      @media only screen and (max-width: 425px) {
        margin-bottom: 0;
      }

      img {
        margin-block: 100px;
        height: 700px;
        width: 30%;
        object-fit: cover;

        @media only screen and (max-width: 1024px) {
          height: 500px;
        }

        @media only screen and (max-width: 779px) {
          height: 450px;
          width: 40%;
        }

        @media only screen and (max-width: 590px) {
          height: 450px;
          width: 60%;
        }

        @media only screen and (max-width: 320px) {
          height: 450px;
          width: 90%;
        }
      }

      .text-card {
        width: 30%;
        display: flex;
        flex-direction: column;
        gap: 10px;

        @media only screen and (max-width: 779px) {
          margin-inline: 50px;
          width: 100%;
        }

        h3 {
          font-size: 1.5rem;
          font-weight: 550;
          color: black;
          margin-bottom: 20px;
          text-align: center;

          @media only screen and (max-width: 1024px) {
            font-size: 1.2rem;
          }

          @media only screen and (max-width: 768px) {
            font-size: 1rem;
          }
        }

        .descr-shop {
          font-size: 1rem;
          color: $text;
          text-align: justify;

          @media only screen and (max-width: 1024px) {
            font-size: 0.85rem;
          }

          @media only screen and (max-width: 768px) {
            font-size: 0.75rem;
          }

          @media only screen and (max-width: 537px) {
            font-size: 11px;
          }
        }
      }
    }
  }

  @include carouselLogo;

  .cards-categories {
    padding: 20px;
    margin-bottom: 150px;
    text-align: center;

    @media only screen and (max-width: 425px) {
      margin-bottom: 30px;
      padding: 10px;
    }

    @media only screen and (max-width: 320px) {
      padding: 0px;
    }

    h2 {
      display: flex;
      justify-content: center;
      font-size: 2rem;
      font-weight: 100;
      margin-bottom: 80px;

      @media only screen and (max-width: 425px) {
        font-size: 1.5rem;
        margin-bottom: 35px;
      }

      @media only screen and (max-width: 320px) {
        font-size: 1.4rem;
      }
    }
  }

  @include carouselNews;
  @include info;
}