.product__boxes {
  padding: 80px 20px 80px 20px;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 30px;

  @media only screen and (max-width: 320px) {
    margin-bottom: 70px;
  }

  .box {
    width: 30%;

    @media only screen and (max-width: 425px) {
      width: 43%;
    }

    @media only screen and (max-width: 320px) {
      width: 70%;
    }

    img {
      width: 100%;
      height: 280px;
      object-fit: cover;

      @media only screen and (max-width: 425px) {
        height: 150px;
      }
    }
  }

  &::before {
    content: "";
    width: 500px;
    height: 500px;
    position: absolute;
    top: 0;
    left: 0;
    background-color: $style-gradient;
    z-index: -1;

    @media only screen and (max-width: 768px) {
      width: 250px;
      height: 250px;
    }

    @media only screen and (max-width: 425px) {
      display: none;
    }
  }

  &::after {
    content: "";
    width: 500px;
    height: 500px;
    position: absolute;
    bottom: 0;
    right: 0;
    background-color: $style-gradient;
    z-index: -1;

    @media only screen and (max-width: 768px) {
      width: 250px;
      height: 250px;
    }

    @media only screen and (max-width: 425px) {
      display: none;
    }
  }
}
