.home {

    .shop {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        gap: 20px;
        margin: 100px 50px;

        .shop__card {
            width: 500px;
            height: 350px;

            img {
                width: 100%;
                height: 250px;
                object-fit: cover;
            }

            .shop__info {
                display: flex;
                flex-direction: column;
                row-gap: 10px;

                h3 {
                    font-size: 14px;
                    font-weight: 500;
                }

                p {
                    color: $text;
                    font-size: 11px;
                }

                a {
                    display: flex;
                    align-items: center;
                    column-gap: 5px;
                    font-size: 11px;

                    svg {
                        width: 20px;
                        height: 20px;
                    }
                }
            }

        }
    }

    .product-range {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        margin-block: 100px;

        h3 {
            font-size: 2rem;
            font-weight: 100;
        }

        .product-range__content {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            align-items: center;
            margin-top: 50px;
            gap: 20px;

            .product__block {
                display: flex;
                flex-direction: column;
                gap: 20px;

                .block__img img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }

                .small,
                .block__color {
                    width: 250px;
                    height: 250px;
                }

                .long {
                    width: 250px;
                    height: 450px;
                }

                .block__color {
                    background-color: $style;
                }
            }
        }

    }

    @include carouselLogo;

    @include carouselNews;

    .google-review {
        margin-bottom: 100px;
    }

}